body {
  margin: 0;
}

#__next {
  margin: 0;
}

@media (min-width: 1020px) {
  .lbh-header__service-name {
    margin-left: 1em;
  }
}

.lbh-select--full-width {
  width: 100%;
}

.lbh-cookie-banner {
  .js-enabled & {
    top: auto;
    bottom: 0;
  }
}

.lbh-\!-margin-top-0 {
  margin-top: 0 !important;
}

.lbh-\!-margin-top-1 {
  margin-top: 10px !important;
}

.lbh-\!-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.lbh-\!-margin-bottom-1 {
  margin-bottom: 10px !important;
}

.lbh-\!-margin-left-1 {
  margin-left: 10px !important;
}

.lbh-\!-font-weight-bold {
  font-weight: 600 !important;
}

.lbh-\!-no-wrap {
  white-space: nowrap;
}

.lbh-body--grey {
  color: #525a5b;
}

.lbh-caption-xl {
  @include lbh-heading-h1;
  color: #525a5b;
  margin-top: 0.2em;
}

.lbh-list--compressed > li + li {
  margin-top: 7px;
  margin-top: 0.44rem;
}

.lbh-link-group--spaced {
  margin-bottom: 4em;
}

.lbh-dialog__close {
  margin-top: 0;
  right: 0;
  top: 0;
  padding: 15px;
}

.lbh-header {
  z-index: 0;
}

.lbh-footer {
  background-color: #f8f8f8;
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.lbh-input--large {
  height: 50px;
  padding: 5px 10px;
}

.lbh-link--announcement:link,
.lbh-link--announcement:visited {
  color: #0b0c0c;
}

.lbh-button--inline {
  margin-left: 0;
  margin-top: 0.5em;
  min-width: 5em;

  @media (min-width: 40.0625em) {
    margin-top: 0;
    margin-left: 0.5em;
  }
}

.govuk-radios__item--fixed-width {
  width: 40%;
}

.lbh-simple-pagination {
  border-top: 0;
  margin-top: 0;
}

.lbh-flex-heading {
  > button {
    margin-top: 0;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lbh-table--small {
  font-size: 0.8em;
  line-height: 1.5em;
}

.lbh-\!-break-word {
  word-break: break-word;
}

.lbh-audit-history-json {
  white-space: pre-wrap;
}
